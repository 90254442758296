var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.defaultActive,
          expression: "!defaultActive",
        },
      ],
      staticClass: "menu-container",
    },
    [
      _c(
        "el-menu",
        {
          ref: "elMenu",
          attrs: {
            "default-active": _vm.defaultActive,
            "background-color": (_vm.currentTheme || {}).menuBackgroundColor,
            "text-color": (_vm.currentTheme || {}).menuTextColor,
            "active-text-color": _vm.isMobileScreen ? "#0F376A" : "#fff",
            "unique-opened": true,
            collapse: _vm.collapse,
          },
        },
        [
          _vm._l(_vm.leftMenulist, function (menu) {
            return [
              menu.children.length && menu.enable
                ? _c(
                    "el-submenu",
                    { key: menu.name, attrs: { index: menu.id } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("em", { class: menu.icon }),
                        _c("span", [_vm._v(_vm._s(_vm.$t(menu.name)))]),
                      ]),
                      _vm._l(menu.children, function (item) {
                        return [
                          item.enable
                            ? _c(
                                "el-menu-item",
                                {
                                  key: item.id,
                                  attrs: { index: `/admin/${item.router}` },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("toCurrentPage", item)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "item" }, [
                                    _c("em", { class: item.icon }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t(item.name))),
                                    ]),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              !menu.children.length && menu.enable
                ? _c(
                    "el-menu-item",
                    {
                      key: menu.id,
                      attrs: { index: `/admin/${menu.router}` },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("toCurrentPage", menu)
                        },
                      },
                    },
                    [
                      _c("em", { class: menu.icon }),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(" " + _vm._s(_vm.$t(menu.name)) + " "),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "padding-left": "30px" } }, [
      _c("span", { staticStyle: { color: "#303133", "font-size": "12px" } }, [
        _vm._v("Version: 1.16.5"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }