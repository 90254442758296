<template>
  <div class="schedul-detail">
    <div class="btn-container">
      <!--el-button
        v-if="jobData.id && schedulFormData.mitigatingCompanyOrgId === currentCompany.id"
        round
        @click="() => (showEmailRecipientDialog = true)"
        >Recipients</el-button
      -->
      <!--取消订单-->
      <el-button
        v-if="
          ![4, 5].includes(jobData.status) &&
          jobData.status &&
          !isCanceled &&
          hasPermission($permissionFunctionCode.EDIT) &&
          isJobCreatorEdit
        "
        style="min-width: 100px"
        type="danger"
        size="small"
        round
        :loading="cancelLoading"
        @click="cancelJop"
      >
        Cancel Job
      </el-button>
      <!--提交-->
      <el-button
        v-if="![3, 4, 5].includes(jobData.status) && isJobCreatorEdit"
        style="min-width: 100px"
        type="primary"
        size="small"
        round
        :loading="submitLoading"
        @click="submitForm"
      >
        Submit
      </el-button>
      <el-button
        v-if="jobData.status == 4 && isJobCreatorEdit"
        style="min-width: 100px"
        type="primary"
        size="small"
        round
        :loading="submitLoading"
        @click="submitReferenceNo"
      >
        Submit Reference No
      </el-button>
      <!--关闭当前页-->
      <el-button
        style="min-width: 100px"
        outline
        size="small"
        round
        @click="$emit('close', (noAction = true))"
      >
        Close
      </el-button>
    </div>
    <div style="overflow-y: auto; height: calc(100% - 42px)">
      <el-form
        ref="schedulForm"
        :model="schedulFormData"
        :disabled="isCanceled || [3, 4, 5].includes(status) || !isJobCreatorEdit"
        label-position="top"
        :validate-on-rule-change="false"
      >
        <base-detail-card
          title="Files"
          v-if="jobData.status && [3, 4].includes(jobData.status)"
        >
          <job-files :jobFiles.sync="jobFiles" />
        </base-detail-card>
        <base-detail-card title="STS Information"  v-if="jobData.status && [3, 4].includes(jobData.status)" v-loading="!jobDataInner">
          <el-form
            class="main-scroll-form"
            ref="deliveredScheduleForm"
            :model="jobDataInner"
            label-position="top"
            :validate-on-rule-change="false"
          >
            <el-row  :gutter="10">
              <el-col :sm="12" :md="8" :lg="4">
                <el-form-item label="Product Name">
                  <el-input
                    class="single-border"
                    readonly
                    :value="(jobDataInner || {}).productName"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :md="8" :lg="4">
                <el-form-item label="Barge Name">
                  <el-input
                    class="single-border"
                    readonly
                    :value="(jobDataInner || {}).bargeName"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :md="8" :lg="4">
                <el-form-item label="eBDN Date" style="width: 100%">
                  <el-date-picker
                    class="single-border"
                    v-model="(jobDataInner || {}).docDate"
                    type="date"
                    readonly
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :md="8" :lg="4">
                <el-form-item label="Delivered Quantity">
                  <el-input
                    class="single-border"
                    readonly
                    :value="numericByCompanySetting((jobDataInner || {}).docQty)"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :md="8" :lg="4">
                <el-form-item label="ETC">
                  <el-date-picker
                    class="single-border"
                    v-model="(jobDataInner || {}).etc"
                    type="date"
                    readonly
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :md="8" :lg="4">
                <el-form-item v-if="(jobDataInner || {}).bdnPdfId" label="BDN Document">
                  <el-button
                    type="primary"
                    plain
                    @click="previewFileMixin((jobDataInner || {}).bdnPdfId)"
                    style="width: 100%"
                  >
                    {{ (jobDataInner || {}).docNumber }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <time-sheet-information v-if="jobDataInner?.timesheet" :jobDataInner ="jobDataInner" :jobData ="jobData" />
          </el-form>
        </base-detail-card>
        <base-detail-card title="Product Information" >
          <el-row :gutter="10">

            <el-col :sm="12" :md="8">
              <el-form-item
                prop="sysProductId"
                label="Product Name"
                :rules="rules.reqRule"
              >
                <!--   @change="schedulFormData.fuelTypeCode = null" -->
                <el-select
                  v-model="schedulFormData.sysProductId"
                  style="width: 100%"
                  placeholder="Select"
                  @visible-change="getProductListSortByRecent"
                  @change="handleChangeProduct"
                  :loading="!sysProductOptions.length>0"
                  :disabled="disabledForVictory"
                >
                  <el-option
                    v-for="item in sysProductOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item
                prop="sysProductSpecificationId"
                label="Spec"
                :rules="rules.reqRule"
              >
                <el-select
                  v-model="schedulFormData.sysProductSpecificationId"
                  style="width: 100%"
                  placeholder="Select"
                  :disabled="disabledForVictory"
                >
                  <el-option
                    v-for="item in sysProductSpecificationOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item
                prop="finalQty"
                label="Transfer Quantity"
                :rules="rules.reqRule"
              >
                <el-input
                  v-model="schedulFormData.finalQty"
                  type="number"
                  class="input-with-select"
                >
                  <el-select
                    v-model="schedulFormData.qtyUnitMetric"
                    slot="append"
                    disabled
                  >
                    <el-option value="MT"></el-option>
                    <el-option value="CBM"></el-option>
                    <el-option value="BARRELS"></el-option>
                    <el-option value="%"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">

            <el-col :sm="12" :md="8">
              <el-form-item
                prop="fuelTypeCode"
                label="Fuel Type (For MPA Reporting)"
                :rules="getFuelCodeRules"
              >
                <el-select
                  v-model="schedulFormData.fuelTypeCode"
                  style="width: 100%"
                  placeholder="Select"
                  @visible-change="getMpaFuelTypeListSortByRecent"
                  :loading="!mpaFuelTypeOptions.length>0"
                  :disabled="disabledForVictory"
                >
                  <el-option
                    v-for="item in mpaFuelTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item prop="nominationReference" :label="nominationReferenceLabel">
                <el-input v-model="schedulFormData.nominationReference" />
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form v-if="jobData.status==4 && isJobCreatorEdit">
                <el-form-item prop="referenceNo" label="Reference No.">
                  <el-input v-model="schedulFormData.referenceNo" />
                </el-form-item>
              </el-form>
              <el-form-item v-else prop="referenceNo" label="Reference No.">
                <el-input v-model="schedulFormData.referenceNo" />
              </el-form-item>
            </el-col>
          </el-row>
           <fuel-type-related-information
            :schedulFormData.sync="schedulFormData"
          />
        </base-detail-card>
        <!-- bnd info -->
        <base-detail-card title="BDN Information">
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <el-form-item prop="bargeOrgId" label="BDN" :rules="rules.reqRule">
                <el-select
                  filterable
                  clearable
                  v-model="schedulFormData.bargeOrgId"
                  style="width: 100%"
                  placeholder="Select"
                  @change="(val) => handleChangeBDN(val, true)"
                >
                  <el-option
                    v-for="item in physicalSupplierOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" v-if="isExxonMobil">
              <el-form-item prop="customerName" label="Customer Name" :rules="rules.reqRule">
                <el-input v-model="schedulFormData.customerName" />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item label="Operation Date Base On" :rules="rules.reqRule">
                <el-select v-model="schedulFormData.selectedOperationDateType
                " style="width: 100%;" placeholder="Please Select" :disabled="isDisabledAllowOperationDateChange">
                  <el-option v-for="item in STS_OPERATION_DATE_OPTIONS" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </base-detail-card>
        <!-- barde info -->
        <base-detail-card title="Barge Information">
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <!--  prop="bargeImo" -->
              <el-form-item
                v-if="
                  schedulFormData.mitigatingCompanyOrgId === currentCompany.id || !isEdit
                "
                label="Delivering Barge Name"
                prop="bargeName"
                :rules="rules.reqRule"
              >
              <barge-or-vessel-selector
                :disabled="[2, 3, 4, 5].includes(jobData.status)"
                :schedulFormData="schedulFormData"
                :availableBargesLoading="false"
                :availableBargesOptions="currentCompanyShips.filter(item => !((item.imo && item.imo === schedulFormData.vesselImo) ||(item.id === schedulFormData.vesselShipId)))"
                @handleChangeBarge="(val) => changeVesselName(val, true)"
                @visibleChange="()=>{}"
              />

                <span v-if="supplyingBargeMpaStatus" style="font-size: 10px"
                  >({{ supplyingBargeMpaStatus }})</span
                >
              </el-form-item>
              <el-form-item v-else label="Delivering Barge Name">
                <el-input v-model="schedulFormData.bargeName" disabled />
                <span v-if="supplyingBargeMpaStatus" style="font-size: 10px"
                  >({{ supplyingBargeMpaStatus }})</span
                >
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item label="Delivering Barge IMO">
                <el-input v-model="schedulFormData.bargeImo" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item label="Delivering Barge SB No.">
                <el-input v-model="supplyingBarge.licenceNo" disabled> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <!-- <el-form-item
                v-if="
                  schedulFormData.mitigatingCompanyOrgId === currentCompany.id || !isEdit
                "
                prop="vesselName"
                label="Receiving Barge Name"
                :rules="rules.reqRule"
              >
                <el-input v-if="createNewVessel" v-model="schedulFormData.vesselName">
                </el-input>
                <el-select
                  popper-class="custom-ship-selector"
                  v-else
                  v-model="schedulFormData.vesselName"
                  style="width: 100%"
                  placeholder="Select"
                  @change="(val) => changeVesselName(val, false)"
                >
                  <span
                    style="
                      display: inline-block;
                      width: 200px;
                      font-size: 14px;
                      font-weight: 500;
                      margin-left: 20px;
                      margin-bottom: 10px;
                    "
                    >Name</span
                  >
                  <span
                    style="
                      display: inline-block;
                      width: 100px;
                      font-size: 14px;
                      font-weight: 500;
                    "
                    >IMO</span
                  >
                  <span style="display: inline-block; font-size: 14px; font-weight: 500"
                    >SB No.</span
                  >
                  <el-option
                    v-for="item in currentCompanyShips"
                    :disabled="
                      (item.imo && item.imo === schedulFormData.bargeImo) ||
                      item.id === schedulFormData.bargeShipId
                    "
                    :key="item.id"
                    :label="item.shipName"
                    :value="item.id"
                  >
                    <span style="display: inline-block; width: 200px; font-size: 12px">{{
                      item.shipName
                    }}</span>
                    <span style="display: inline-block; width: 100px; font-size: 12px">{{
                      item.imo
                    }}</span>
                    <span style="display: inline-block; font-size: 12px">{{
                      item.licenceNo
                    }}</span>
                  </el-option>
                </el-select>
                <span v-if="receivingBargeMpaStatus" style="font-size: 10px"
                  >({{ receivingBargeMpaStatus }})</span
                >
              </el-form-item> -->
              <el-form-item  v-if=" schedulFormData.mitigatingCompanyOrgId === currentCompany.id || !isEdit
                " label="Search Receiving Barge" >
              <search-vessel-selector
              :disabled="isCanceled || [3, 4, 5].includes(status) || !isJobCreatorEdit"
              :searchedVesselList.sync="searchedVesselList"
              :schedulFormData="schedulFormData"
              @handleSelectVessel="(val)=>changeVesselName(val, false)"
             />

            </el-form-item>
            <el-form-item v-else label="Receiving Barge Name">
              <el-input v-model="schedulFormData.vesselName" :disabled="isCanceled || [3, 4, 5].includes(status) || !isJobCreatorEdit" />

            </el-form-item>
            </el-col>
            <el-col v-if="showDBvesselName" :sm="24" :md="16">
              <el-form-item
                prop="vesselNameDatabase"
                label="Vessel Name in Database"
              >
                <template slot="label">
                  <div>Vessel Name in Database <span style="color:red">(Difference from Receiving Barge Name)</span></div>
                </template>
                <el-input
                  v-model="schedulFormData.vesselNameDatabase"
                  :disabled="true"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <el-form-item label="Receiving Barge Name">
                <el-input v-model="schedulFormData.vesselName" :disabled="isCanceled || [3, 4, 5].includes(status) || !isJobCreatorEdit"/>
                <span v-if="receivingBargeMpaStatus" style="font-size: 10px"
                  >({{ receivingBargeMpaStatus }})</span
                >
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item prop="vesselImo" label="Receiving Barge IMO" :rules="rules.vesselImoNo">
                <el-input
                  v-model="schedulFormData.vesselImo"
                  :disabled="isCanceled || [3, 4, 5].includes(status) || !isJobCreatorEdit"
                  @change="(val) => getVessel(val, 'imo', getVesselCallback)"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item label="Receiving Barge SB No." prop="vesselLicenseNo" :rules="rules.validateLicenseNo">
                <el-input
                  v-model="schedulFormData.vesselLicenseNo"
                  :disabled="isCanceled || [3, 4, 5].includes(status) || !isJobCreatorEdit"
                  @change="(val) => getVessel(val, 'licenceNo', getVesselCallback)"
                > </el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </base-detail-card>
        <!-- Location info -->
        <base-detail-card title="Location Information">
          <el-row :gutter="10">
            <el-col :sm="12" :md="8">
              <el-form-item prop="port" label="Port">
                <el-input v-model="schedulFormData.port"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item prop="imo" label="Date & Time">
                <el-date-picker
                  v-model="schedulFormData.vesselEta"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  style="width: 100%"
                  placeholder="Select"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item prop="locationLevel" label="Anchorage/Berth">
                <el-cascader
                  clearable
                  popper-class="el-cascader-long"
                  style="width: 100%"
                  v-model="schedulFormData.locationLevel"
                  :options="locationList"
                  :props="locationOptionProps"
                  :show-all-levels="false"
                >
                </el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
        </base-detail-card>
        <base-detail-card title="Remarks">
          <el-row>
            <el-form-item prop="remark">
              <el-input type="textarea" :rows="2" v-model="schedulFormData.remark">
              </el-input>
            </el-form-item>
          </el-row>
        </base-detail-card>
        <el-form>
          <base-detail-card v-if="jobData.id" title="Audit Trails">
            <audit-trails-information :jobId="jobData.id" />
          </base-detail-card>
          <base-detail-card
            title="MPA Transaction Information"
            v-if="jobData.status === 4"
            :action="mpaInfoActions"
          >
            <mpa-transaction-information :mpaTransactionData="mpaTransactionData" />
          </base-detail-card>
          <base-detail-card
            title="SgTradex Transaction Information"
            v-if="
              sgTradexState && jobData.status === 4 && sgTradexState.isSgTradexInfoVisible
            "
            :action="sgTradexState.actions"
          >
            <sg-tradex-transaction-information
              :sgTradexTransactionData.sync="sgTradexState.sgTradexTransactionData"
              :loading.sync="sgTradexState.sgTradexTransactionLoading"
            />
          </base-detail-card>
        </el-form>
      </el-form>
      <email-recipients
        v-if="jobData.id"
        :jobId="jobData.id"
        :jobStatus="jobData.status"
        :showDialog="showEmailRecipientDialog"
        @on-dialog-closed="() => (showEmailRecipientDialog = false)"
      />
    </div>
  </div>
</template>

<script>
import { HEADER_CELL_STYLE, CELL_STYLE, VICTORY_PRODUCTS_FIXED, STS_OPERATION_DATE_OPTIONS } from "@/constants.js";
import BaseDetailCard from "@/components/BaseDetailCard";
import { mapState } from "vuex";
import baseMixin from "@/mixins/base-mixin";
import baseTableMixin from "@/mixins/base-table-mixin";
import { getJobFiles,updateReferenceNo } from "@/services/modules/ops.js";
import jobPeriphery from "@/mixins/job-periphery";
import MpaTransactionInformation from "./MpaTransactionInformation.vue";
import SgTradexTransactionInformation from "./SgTradexTransactionInformation.vue";
import AuditTrailsInformation from "./AuditTrailsInformation.vue";
import JobFiles from "./JobFiles.vue";
import EmailRecipients from "./EmailRecipients.vue";
import FuelTypeRelatedInformation from "./FuelTypeRelatedInformation.vue"
import SearchVesselSelector from './searchVesselSelector.vue'
import {getAuthorizedBdnCompanies,getShipByShipId} from "@/services/modules/comm.js";
import TimeSheetInformation from './TimeSheetInformation.vue';
import BargeOrVesselSelector from './BargeOrVesselSelector.vue';
import { sortListByRecent, isValidImoNo } from "@/utils";
export default {
  props: {
    jobData: {
      type: Object,
      default: () => {},
    },
    locationOptionProps: Object,
    mpaTransactionData: Array,
    hasSysAdminPermission: Boolean,
    sgTradexState: {
      type: Object,
      required: true,
    },
    currentCompanyShips: Array,
  },
  mixins: [baseTableMixin, jobPeriphery, baseMixin],
  components: {
    BaseDetailCard,
    MpaTransactionInformation,
    AuditTrailsInformation,
    SgTradexTransactionInformation,
    JobFiles,
    EmailRecipients,
    FuelTypeRelatedInformation,
    SearchVesselSelector,
    TimeSheetInformation,
    BargeOrVesselSelector
  },
  data() {
    return {
      HEADER_CELL_STYLE,VICTORY_PRODUCTS_FIXED,
      STS_OPERATION_DATE_OPTIONS,
      CELL_STYLE,
      jobDataInner: null,
      status: null,
      productsOption: null,
      locationList: this.$store.state.locationList,
      submitLoading: false,
      cancelLoading: false,
      isEdit: false,
      isCanceled: false,
      createNewVessel: false,
      imoDisabled: true,
      sysProductOptions: [],
      sysProductSpecificationOptions: [],
      schedulFormData: {
        qtyUnitMetric: "MT",
        toleranceFigUnit: "MT",
        sysProductId: null,
        sysProductSpecificationId: null,
        port: "Singapore",
        vesselShipId: null,
        vesselLicenseNo: null,
        vesselName: null,
        vesselNameDatabase: null,
        vesselImo: null,
        vesselEmail: null,
        bargeShipId: null,
        bargeName: null,
        bargeImo: null,
        // bargeLicenseNo: null,
        bargeOrgId: "",
        remark: "",
        fuelTypeCode: null,
        blendRatio: null,
        biofuelGenCode: null,
        biofuelISCC: null,
        biofuelISCCValue: null,
        biofuelCertScheme: null,
        typeOfFeedstock: null,
        customerName: null,
        nominationReference: null,
        selectedOperationDateType: null,
      },
      productOptions: [
        "MGO DMA 1.5%S MAX",
        "MGO DMA 0.5%S MAX",
        "LSMGO DMA 0.5%S MAX",
        "LSMGO DMA 0.1%S MAX",
        "HSFO 3.5% S MAX RME180",
        "HSFO 3.5% S MAX RMG380",
        "HSFO 3.5% S MAX RMK500",
        "VLSFO 0.5% S MAX RME180",
        "VLSFO 0.5% S MAX RMG380",
        "ULSFO 0.1% S MAX RME180",
      ],
      sulphurOptions: ["0.10% S Max", "0.50% S Max", "1.50% S Max", "3.50% S Max"],
      specsOptions: ["ISO 8217:2005", "ISO 8217:2010", "ISO 8217:2012", "ISO 8217:2017"],
      rules: {
        reqRule: [
          {
            required: true,
            message: "This field is required",
            trigger: ["change", "blur"],
          },
        ],
        vesselImoNo: [
          {
            validator: (rule, value, callback) => {
              /*
              if(! value && ! this.schedulFormData.vesselLicenseNo) {
                callback("Either Receiving Barge Imo or Receiving Barge SB No. Number must have value.");
              }
              */

              if (!(!value || value.trim() === "") && !isValidImoNo(value)) {
                callback("Invalid IMO No.");
              }

              callback();
            },
            trigger: ["change", "blur"],
          },
        ],
        validateLicenseNo: [
          {
            validator: (rule, value, callback) => {
            //  this.$refs.schedulForm.validateField('vesselImo')
              callback();
            },
            trigger: ["change", "blur"],
          }
        ]
      },
      mpaInfoActions: null,

      jobFiles: {},
      showEmailRecipientDialog: false,
      isJobCreatorEdit: true,
      authorizedBdnCompanies:[],
      searchedVesselList:[],
      mpaFuelTypeOptions:[],
      isExxonMobil: false,
      receivingBargeMpaStatus:'',
      supplyingBargeMpaStatus:'',
      receivingBarge:{},
      supplyingBarge:{},
    };
  },
  computed: {
    ...mapState({
      currentCompany: "currentCompany",
      currentCompanySettings: "currentCompanySettings",
      companyList: "companyList",
      mpaFuelTypeList: "mpaFuelTypeList",
      productList: "productList",
      productSpecificationList: "productSpecificationList",
      physicalSupplierList: "physicalSupplierList",
      isVictory: "isVictory",
    }),

    companyOpts() {
      return this.companyList.map((item) => ({
        value: item.id,
        label: item.companyName,
        licenseNumber: item.licenseNumber,
      }));
    },

    licenseNumbercompanys() {
      return this.companyOpts.filter((item) => item.licenseNumber);
    },

    getFuelCodeRules() {
      const rules = [
        { required: true, message: this.$t("rejectBeEmpty"), trigger: "change" },
      ];
      return rules;
    },

    physicalSupplierOptions(){
      const result = [...this.authorizedBdnCompanies];
      if (this.isEdit && this.schedulFormData?.bargeOrgId) {

        let existingBdnCompany = result.find( (c) => c.id === this.schedulFormData.bargeOrgId );
        if (!existingBdnCompany) {
          existingBdnCompany = this.physicalSupplierList.find( (c) => c.id === this.schedulFormData.bargeOrgId );
          if (existingBdnCompany) result.push(existingBdnCompany);
        }
      }
      return result.map(item => {return {
        value: item.id,
        label: item.companyName,
        isExxonMobil: item.isExxonMobil
      }});
    },

    nominationReferenceLabel() {
      return this.isExxonMobil ? "SAP Order Number" : "Nomination Reference"
    },

    disabledForVictory() {
      return this.isVictory && (!this.isEdit || this.isJobCreatorEdit)
    },

    showDBvesselName() {
      return (this.schedulFormData?.vesselNameDatabase || this.schedulFormData.vesselName) && this.schedulFormData.vesselName !== this.schedulFormData.vesselNameDatabase
    },

    isDisabledAllowOperationDateChange() {
      return ! this.operationDateCompanySetting?.allowOperationDateChange == true
    }
  },
  methods: {
    isValidImoNo,
    getAuthorizedBdnCompanies,
    sortListByRecent,
    handleGetPhysicalSupplierList(){
      const orgId=  this.isEdit ? this.schedulFormData.mitigatingCompanyOrgId : this.currentCompany.id
      Promise.all([ this.getAuthorizedBdnCompanies(orgId),this.$store.dispatch('getPhysicalSupplierList')]).then((val) => {
        this.authorizedBdnCompanies = val[0]?.data ?? [];

      });
    },

    setForVictoryFixedProduct(){
      const specDefault = this.productSpecificationList.find(item => item?.name == this.VICTORY_PRODUCTS_FIXED['SPEC']|| item?.year == this.VICTORY_PRODUCTS_FIXED['SPEC_YEAR'])
      const productDefault = this.productList.find(item => item?.name == this.VICTORY_PRODUCTS_FIXED['PRODUCT'])
      const fuelTypeDefault = this.mpaFuelTypeList.find(item => item?.value == this.VICTORY_PRODUCTS_FIXED['FUEL_TYPE'])
      this.schedulFormData.sysProductId = productDefault?.id
      this.schedulFormData.sysProductSpecificationId = specDefault?.id
      this.schedulFormData.fuelTypeCode =fuelTypeDefault?.value ?? this.VICTORY_PRODUCTS_FIXED['FUEL_TYPE']
    },
    getVesselCallback(record) {
      this.searchedVesselList = [record]
      this.changeVesselName(record.id, false)
    },
    changeVesselName(shipId, isBarge) {
      const vessel = isBarge && this.currentCompanyShips?.length > 0 ? this.currentCompanyShips.find((v) => v.id == shipId):this.searchedVesselList.find((v) => v.id == shipId) ;
      if (isBarge) {
        this.schedulFormData.bargeShipId = shipId;
        // this.schedulFormData.bargeLicenseNo = vessel?.licenceNo
        this.schedulFormData.bargeName = vessel?.shipName;
        this.schedulFormData.bargeImo = vessel?.imo;
      } else {
        this.schedulFormData.vesselShipId = shipId;
        this.schedulFormData.vesselLicenseNo = vessel?.licenceNo
        this.schedulFormData.vesselNameDatabase = vessel?.shipName;
        this.schedulFormData.vesselName = vessel?.shipName;
        this.schedulFormData.vesselImo = vessel?.imo;
        this.schedulFormData.vesselEmail = vessel?.email;
        this.$refs.schedulForm.validateField('vesselImo')
      }
      this.syncShipWithMPA(vessel?.imo, vessel?.licenceNo);
    },
    submitForm() {
      this.$refs.schedulForm.validate((valid) => {
        if (valid) {
          this.checkVesselImoAndLicenceNo(this.schedulFormData, () => {
            this.sendFormData();
          })
        } else {
          this.$message.error("Error. Required fields not filled in.");
          return false;
        }
      });
    },
    async sendFormData() {
      const locationLevel1 =
        this.schedulFormData.locationLevel && this.schedulFormData.locationLevel[0]
          ? this.schedulFormData.locationLevel[0]
          : "";
      const locationLevel2 =
        this.schedulFormData.locationLevel && this.schedulFormData.locationLevel[1]
          ? this.schedulFormData.locationLevel[1]
          : "";

      const data = Object.assign({}, this.schedulFormData, {
        type: 3,
        vesselEta: this.schedulFormData.vesselEta
          ? new Date(this.schedulFormData.vesselEta).toISOString()
          : null,
        locationLevel1,
        locationLevel2,
      });

      if (this.createNewVessel) {
        const newShipData = {
          imo: this.schedulFormData.vesselImo,
          loginable: false,
          shipName: this.schedulFormData.vesselName,
          tenantId: "noCompany",
        };

        await this.$request.post({
          url: this.$apis.saveShip,
          data: newShipData,
        });
      }
      if (this.isEdit) {
        // 调用编辑接口
        this.submitLoading = true;
        this.$request
          .put({
            url: this.$apis.editDeliveredJob,
            data,
          })
          .then((data) => {
            if (data?.code === 1000) {
              this.$message.success(data.message);
              this.$emit("close");
            } else {
              this.$message.error(data.message);
            }
          });
      } else {
        // 调用创建接口
        this.submitLoading = true;
        this.$request
          .post({
            url: this.$apis.createNewJob,
            data,
          })
          .then((data) => {
            if (data?.code === 1000) {
              this.$message.success(data.message);
              this.$emit("close");
            } else {
              this.$message.error(data.message);
            }
          });
      }
      this.$emit("refresh-company-ships");
      this.submitLoading = false;
    },

    submitReferenceNo () {
      this.submitLoading = true;
      updateReferenceNo(this.jobData.id,{referenceNumber: this.schedulFormData.referenceNo}).then((resp) => {
        if (resp?.code === 1000) {
          this.$message.success(resp.message);
          this.$emit("close");
        } else {
          this.$message.error(resp.message);
        }
      })
      .finally(async () => {
        this.submitLoading = false;
      });
    },
    cancelJop() {
      this.$confirm("", "Confirm cancel?", {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
        center: true,
        roundButton: true,
      })
        .then(() => {
          this.cancelLoading = true;
          const url = `ebdn/jobs/${this.jobData.id}/cancel`;
          this.$request
            .put({
              url,
            })
            .then((res) => {
              if (res?.code === 1000) {
                this.isCanceled = true;
                this.$message.success("Job cancelled successfully");
                this.$emit("close");
              }
            })
            .finally(() => {
              this.cancelLoading = false;
            });
        })
        .catch(() => {});
    },
    onCreateNewVesselChange(value) {
      this.imoDisabled = !value;

      if (value) {
        const vesselImoRule = vesselImoNo;
        const vesselNameRule = [
          { required: true, message: "Enter the Receiving Barge Name", trigger: "blur" },
        ];
        this.$set(this.rules, "vesselImo", vesselImoRule);
        this.$set(this.rules, "vesselName", vesselNameRule);
      } else {
        this.$delete(this.rules, "vesselImo");
        this.$delete(this.rules, "vesselName");
        this.$refs.schedulForm.clearValidate("vesselName");
        this.$refs.schedulForm.clearValidate("vesselImo");
      }

      this.schedulFormData.vesselImo = "";
      this.schedulFormData.vesselName = "";
    },


    // set sys product and specs option list
    getProductsAndSpecsOptions() {
      Promise.all([this.$store.dispatch('getProductList'), this.$store.dispatch('getProductSpecificationList'), this.$store.dispatch("getMpaFuelTypeList")]).then((val) => {
        this.sysProductOptions = val[0];
        this.sysProductSpecificationOptions = val[1];
        this.mpaFuelTypeOptions = val[2];
        if (val[1].length > 0) {
          const defaultSpec = this.sysProductSpecificationOptions.find(
            (spec) => spec.name === "-"
          );
          if (!this.schedulFormData?.sysProductSpecificationId && defaultSpec) {
            this.schedulFormData.sysProductSpecificationId = defaultSpec.id;
          }
        }
      });
    },
    getProductListSortByRecent (isVisit) {
      if (!this.schedulFormData?.bargeShipId ||this.schedulFormData?.bargeShipId?.trim()=='' ||!isVisit) return
      this.$request.get({
        url: `${this.$apis.recentProducts}?shipId=${this.schedulFormData.bargeShipId}`
      }).then(res => {
        if (res?.code === 1000 && res.data?.length>0) {
          const _arr =this.sortListByRecent(this.productList,res.data);
          this.sysProductOptions= _arr
        }
      })
    },
    getMpaFuelTypeListSortByRecent (isVisit) {
      if ( !isVisit ) return
      const productItem =this.schedulFormData?.sysProductId ? this.sysProductOptions.find(product=>product.id == this.schedulFormData?.sysProductId):null
      if (productItem?.oilType.trim()==''|| !productItem?.oilType) {
        this.mpaFuelTypeOptions = [];
      }else{
        this.$store.dispatch("getFilterMpaFuelTypeList",{oilType:productItem.oilType,shipId:this.schedulFormData.bargeShipId}).then((data) => {
          this.mpaFuelTypeOptions = data
        })
      }
    },
    getJobInfoByJobId(jobId) {
      const url = `${this.$apis.createNewJob}/${jobId}/info`;
      this.$request
        .get({
          url,
        })
        .then((res) => {
          if (res?.code === 1000) {
            this.jobDataInner = {
              ...res.data,
            };
            if (this.jobDataInner.mpaPdfId) {
              this.mpaInfoActions = {
                label: "View EBDN File",
                onAction: () => {
                  this.previewFileMixin(this.jobDataInner.mpaPdfId);
                },
              };
            }
          }
        });
    },
    handleChangeProduct(){
      this.schedulFormData.fuelTypeCode = null
    },
    async handleChangeBDN(bargeOrgId, isChangeOperationDate) {
      if(bargeOrgId) {
        await this.$store.dispatch("isExxonMobil", bargeOrgId).then(res => this.isExxonMobil = res)
      } else {
        this.isExxonMobil = false
      }
      if(! this.isExxonMobil ) {
        this.schedulFormData.customerName = null
      }

      if(isChangeOperationDate) {
        this.schedulFormData.selectedOperationDateType = await this.getDefaultOperationDate(bargeOrgId, 3)
      }
    },
    async getSupplyingBargeMpaStatus() {
      const ship = await getShipByShipId(this.schedulFormData.bargeShipId)
      this.supplyingBarge = ship
      if (!ship) return "Not Found";
      if (ship.lastSyncedMpaBargeFound === null) return "Not Check";
      return ship.lastSyncedMpaBargeFound ? "Exist In MPA" : "Not In MPA";
    },

    async getReceivingVesselMpaStatus() {
      const ship = await getShipByShipId(this.schedulFormData.vesselShipId)
      this.receivingBarge =ship;
      if (!ship) return "Not Found";
      if (ship.lastSyncedMpaVesselFound === null) return "Not Check";
      return ship.lastSyncedMpaVesselFound ? "Exist In MPA" : "Not In MPA";
    },
  },

  destroyed() {
    this.$emit("onClearInterval");
  },
  async mounted() {

    try {await this.getProductsAndSpecsOptions();} catch (error) { }

    if (this.jobData && Object.keys(this.jobData).length > 0) {
      this.isEdit = this.jobData.status === 1 || this.jobData.status === 2;
      this.isCanceled = this.jobData.status === 5 || this.jobData.status === 4;
      this.schedulFormData = Object.assign({}, this.jobData, {
        locationLevel: [this.jobData.locationLevel1, this.jobData.locationLevel2],
      });
      this.status = this.jobData.status;

      this.getJobInfoByJobId(this.jobData.id);
      if ([3, 4].includes(this.jobData.status)) {
        getJobFiles(this.jobData.id).then((res) => {
          this.jobFiles = res.data;
        });
      }
      this.isJobCreatorEdit =
        this.currentCompany.id === this.jobData.mitigatingCompanyOrgId;

      try { this.supplyingBargeMpaStatus = await this.getSupplyingBargeMpaStatus()} catch (error) {}
      try {await this.handleChangeBDN(this.jobData.bargeOrgId, false)} catch (error) {}
      try {this.receivingBargeMpaStatus = await this.getReceivingVesselMpaStatus()} catch (error) {}

    } else {
      this.status = 0;
      this.schedulFormData.mitigatingCompanyOrgId = this.currentCompany.id;
      if (this.isVictory) { this.setForVictoryFixedProduct() }
    }

    const operationDateType = await this.getDefaultOperationDate(this.schedulFormData.bargeOrgId, 3)
    if( ! this.schedulFormData.selectedOperationDateType ) {
      this.schedulFormData.selectedOperationDateType = operationDateType
    }

    this.handleGetPhysicalSupplierList()

  },
};
</script>

<style lang="scss" scoped>
.schedul-detail {
  height: 100%;

  .main-scroll-form {
    height: calc(100% - 42px);
    padding-right: 10px;

    .fields {
      .classification {
        width: 100%;

        p {
          margin-top: 0;
          font-weight: bold;
          color: #606266;
        }
        /* sub-module-title */
        .title {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $second-color-font;
          margin: 0;
          padding: 10px 10px 0 10px;
          width: fit-content;
          border-radius: 5px;
        }

        .product-container {
          width: 100%;
          display: flex;

          /deep/ input,
          /deep/ .el-date-editor,
          /deep/ textarea,
          /deep/ .el-input-group__append,
          /deep/ .el-input-group__prepend {
            border: none;
          }

          .delete-btn-block {
            margin-right: 20px;

            > div {
              display: flex;
              align-items: flex-end;
              height: 62px;

              &:first-child {
                height: 84px;
                margin-top: 20px;
              }
            }
          }
        }

        .single-row-container {
          width: calc(100% - 40px);
          box-sizing: border-box;
          overflow-x: auto;
          margin: 0 10px;

          > div {
            display: flex;

            .el-form-item {
              min-width: 150px;
            }
            .delete-btn-block {
              position: absolute;
              right: -30px;
              bottom: 0;
            }
          }
        }

        .delete-btn-item {
          padding: 3px;
        }

        .el-row {
          padding: 10px;

          .cost {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin: 10px 0;
          }
        }
      }

      .product {
        /deep/ input,
        /deep/ .el-date-editor,
        /deep/ textarea,
        /deep/ .el-input-group__append,
        /deep/ .el-input-group__prepend {
          border: none;
        }

        .single-border {
          /deep/ input {
            border-radius: 0;
            border-right: 1px solid #dcdfe680 !important;
          }
        }

        /deep/ .no-border {
          input {
            border: none !important;
          }
        }
      }
    }
  }

  .btn-container {
    text-align: right;
    margin-right: 20px;
  }
  .input-with-select {
    /deep/ .el-select .el-input {
      width: 90px;
    }
    /deep/ .el-input-group__append,
    /deep/ .el-input-group__prepend {
      background-color: #fff;
    }
    .el-select {
      /deep/ .el-input__inner {
        border: none !important;
      }
    }
  }
}
.el-date-editor {
  width: 100%;
}
</style>
